import { createSlice } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";

export type User = {
  id: string
  first_name: string
  lasst_name: string
  email: string
  avatar: string
  role: string
}

interface IUser {
  user: User | null,
  authorization: {
    access_token: string
  }
}

const initialState:IUser = {
  user: null,
  authorization: { access_token: "" },
};

export const persistConfig = {
  storage: storageSession,
  key: "root",
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginUser: (state, { payload }) => {
      state.user = payload?.data?.loginAdmin;
      state.authorization.access_token = payload?.data.accesstoken;
    },
    setUpdatedUser: (state, { payload }) => {
      state.user = payload?.data;
      state.authorization.access_token = payload?.data.access_tokens;
    },
  },
});

export const { setLoginUser, setUpdatedUser } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;
