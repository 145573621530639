import React, { ReactNode } from "react";
import Footer from "../../components/Footer";
import SideNav from "../../components/SideNav";
import TopNav from "../../components/TopNav";
import AuthRoute from "../../HOC/AuthRoute";

const PageContainerLayout = ({ children }: { children: ReactNode }) => {
  return (
    <AuthRoute>
      <div className="bg-grey-600 w-full">
        <div className="w-full flex">
          <SideNav />
          <div className="h-screen layout overflow-y-scroll w-full ">
            <TopNav />
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </AuthRoute>
  );
};

export default PageContainerLayout;
