import React from 'react'

const AlertTriangle = ({fill}: any) => {
  return (
    <svg width="84" height="80" viewBox="0 0 84 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M82.0286 59.4693L54.4997 7.98516C49.1664 -1.98984 34.8372 -1.98984 29.4997 7.98516L1.97084 59.4693C-2.92083 68.6193 3.721 79.6689 14.1168 79.6689H69.8826C80.2743 79.6689 86.9203 68.6152 82.0286 59.4693ZM38.8747 29.6689C38.8747 27.9439 40.2747 26.5439 41.9997 26.5439C43.7247 26.5439 45.1247 27.9439 45.1247 29.6689V46.3356C45.1247 48.0606 43.7247 49.4606 41.9997 49.4606C40.2747 49.4606 38.8747 48.0606 38.8747 46.3356V29.6689ZM42.0831 63.0022C39.7831 63.0022 37.8951 61.1356 37.8951 58.8356C37.8951 56.5356 39.7414 54.6689 42.0414 54.6689H42.0831C44.3873 54.6689 46.2498 56.5356 46.2498 58.8356C46.2498 61.1356 44.3831 63.0022 42.0831 63.0022Z" fill="#DA0000"/>
    </svg>
  )
}

export default AlertTriangle

