import { baseQueryWithReauth, createRequest, createRequestWithParams, CustomError } from "./shared";
import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/query/react";

export const schoolApi = createApi({
  reducerPath: "schoolApi",
  tagTypes: ["universities"],
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
  endpoints: (builder) => ({
    getAllUniverisities: builder.query<any, { page: number; limit: number }>({
      query: ({ page, limit }) => createRequestWithParams(`admin/university/all/`, { page, limit }),
      providesTags: (result, _error, _arg) =>
        result?.data
          ? [...result?.data.universities.data.rows.map(({ id }: { id: string }) => ({ type: "universities", id })), "universities"]
          : ["universities"],
    }),

    getAUniversity: builder.query<any, string>({
      query: (id) => createRequest(`admin/university/view/${id}`),
      providesTags: (_result, _error, id) => [{ type: "universities", id }],
    }),
    programsInAUniversity: builder.query<any, string>({
      query: (id) => createRequest(`admin/program/by-uni/${id}`),
    }),
    getUniversityReviews: builder.query<any, { page: number; limit: number; id:string  }>({
      query: ({ page, limit, id }) => createRequestWithParams(`admin/reviews/all/${id}`, { page, limit }),
    }),
    updateSchool: builder.mutation<any, any>({
      query: ({ id, ...body }) => {
        const form_data = new FormData();
        form_data.append("name", body?.name);
        form_data.append("description", body?.description);
        form_data.append("address", body?.address);
        form_data.append("image", body.image);
        return {
          url: `admin/university/edit/${id}`,
          method: "PATCH",
          body: form_data,
        };
      },
      invalidatesTags: (result, _error, _arg) => [{ type: "universities", id: result?.data?.id }],
    }),
    deleteSchool: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `admin/university/delete/${id}`,
          method: "DELETE",
          body: {},
        };
      },
      invalidatesTags: (result, _error, _arg) => [{ type: "universities", id: result?.data?.id }],
    }),
    createSchool: builder.mutation<any, any>({
      query: (body) => {
        const form_data = new FormData();
        form_data.append("name", body?.name);
        form_data.append("description", body?.description);
        form_data.append("address", body?.address);
        form_data.append("image", body.image);
        form_data.append("country", "Nigeria");
        return {
          url: `admin/university/create`,
          method: "POST",
          body: form_data,
        };
      },
      invalidatesTags: ["universities"],
    }),
    searchSchool: builder.query<any, { page: number; limit: number, name: string }>({
      query: ({page, limit, name}) => createRequestWithParams(`admin/university/search/`, { page, limit, name })
    })
  }),
});

export const { useGetAUniversityQuery, useGetAllUniverisitiesQuery, useUpdateSchoolMutation, useCreateSchoolMutation, useDeleteSchoolMutation, useLazySearchSchoolQuery, useGetUniversityReviewsQuery, useProgramsInAUniversityQuery } =
  schoolApi;
