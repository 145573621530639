import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'

import { RootState } from '../redux/store'

const Auth = () => {
    const { user } = useSelector((store:RootState) => store.authStore)
    const role = user?.role

  return (
    role === "super" ? <Outlet /> : <Navigate to="/application" />
  )
}

export default Auth