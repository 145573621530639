import { useNavigate, useParams } from 'react-router-dom';
import PageContainerLayout from '../../layout/PageContainerLayout';
import Icon from '../../components/Icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import BlogPostView from '../../components/PostsComp/BlogPostView';
import { toast } from 'react-toastify';

const ViewSinglePost = () => {
    const { id } = useParams<{ id: string }>();
    const [post, setPost] = useState<any>();
    const navigate = useNavigate();
    const { authorization:{ access_token}} = useSelector((store: RootState) => store.authStore);

    useEffect(() => {
        const fetchData = async () => {
            const getPost = await axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/post/view-one/${id}`, { headers: { Authorization: `Bearer ${access_token}`}})

            setPost(getPost?.data?.data);
        } 

        fetchData();
    }, []);

    const handleDeletePost = async () => {
        try {
            const sendDelete = await axios.patch(`${process.env.REACT_APP_BACKEND_API}/admin/post/delete/${id}`, {}, { headers: { Authorization: `Bearer ${access_token}`}});

            if (sendDelete?.data?.status === 'success') {
                toast.success('Post deleted');
                navigate('/posts')
              }
        } catch (error) {
            console.log(error)
            toast.error('There was an error deleting this post')
        }
    }

    return (
        <PageContainerLayout>
            <div className="p-5">
                <div className='w-full bg-white h-[15vh]' >
                    <div className='flex justify-between items-center h-full px-4' >
                        <div onClick={()=> navigate(-1)} className='flex cursor-pointer mx-5' >
                            <span className='pr-2' ><Icon id='goback-icon' height={24} width={24} /></span>
                            <span>Back</span>
                        </div>
                        <div className="flex w-auto gap-3">
                            <button onClick={() => {
                                navigate(`/posts/edit/${id}`)
                            }} className={`px-8 rounded-md h-[60px] border-[1px] border-primary text-[#FFF] bg-primary`}>
                                <span className='flex justify-center items-center font-medium text-[16px] leading-[25.6px] ' >
                                    Edit Post
                                </span>
                            </button>
                            <button onClick={() => {
                                handleDeletePost()
                            }} className={`px-8 rounded-md h-[60px] border-[1px] border-primary text-white bg-red-500`}>
                                <span className='flex justify-center items-center font-medium text-[16px] leading-[25.6px] ' >
                                    Delete Post
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                {
                    post && 
                    <BlogPostView title={post?.title} subtitle={post?.subtitle} category={post.category} sections={post?.content} />
                }
            </div>
        </PageContainerLayout>
    )
}

export default ViewSinglePost;
