import { isEmpty } from "lodash";
import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";

const AuthRoute = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  const { authorization } = useSelector((state: RootState) => state.authStore);

  if (isEmpty(authorization?.access_token)) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <>{children}</>;
};

export default AuthRoute;
