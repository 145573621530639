

export const createBlobFromURL = async(url: string, name: string, defaultType = 'image/webp') => {
    const response = await fetch(`${url}`)
    const data = await response.blob()
    return new File([data], name, { type: data.type || defaultType})
}

export const dateDiffernceInDays = (startDate: Date, endDate: Date) => {
    const timeDifference = endDate.getTime() - startDate.getTime()
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
    return daysDifference
}

export const capitalizeFirstLetter = (word: string | undefined): string => {
    const firstLetter = word?.charAt(0);
  
    const firstLetterCap = firstLetter?.toUpperCase();
  
    const remainingLetters = word?.slice(1);
  
    let capitalizedWord = "";
  
    if (firstLetterCap && remainingLetters) {
      capitalizedWord = firstLetterCap + remainingLetters;
    }
  
    return capitalizedWord;
};

export const capitalizeAllFirstLetters = (word?: string): string => {
    if (word) {
      let splitName = word.split(" ");
      splitName = splitName.map((name) => capitalizeFirstLetter(name));
      return splitName.join(" ");
    } else return "";
};

export function calculateDurationInWords(startDate: any, endDate: any) {
 const msInDay = 24 * 60 * 60 * 1000;
 const msInMonth = msInDay * 30; // Approximation
 const msInYear = msInDay * 365; // Approximation

 const diffInMs = endDate - startDate;
 const diffInDays = Math.floor(diffInMs / msInDay);
 const diffInMonths = Math.floor(diffInMs / msInMonth);
 const diffInYears = Math.floor(diffInMs / msInYear);

 if (diffInYears > 0) {
   return `${diffInYears} year${diffInYears > 1 ? 's' : ''}`;
 } else if (diffInMonths > 0) {
   return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''}`;
 } else {
   return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
 }
}
