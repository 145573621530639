import CreatePostTab from "../../components/PostsComp/CreatePostTab";
import PageContainerLayout from "../../layout/PageContainerLayout";
import EditPostForm from "./EditPostForm";

const EditPost = () => {
    
    return (
        <PageContainerLayout>
            <div className="p-5">
                <CreatePostTab />
                <EditPostForm />
            </div>
        </PageContainerLayout>
    )
}

export default EditPost;