import { baseQueryWithReauth, createRequest, CustomError } from "./shared";
import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
  endpoints: (builder) => ({
    signupUser: builder.mutation({
      query: (data) => {
        return {
          url: `user/signup`,
          method: "post",
          body: data,
        };
      },
    }),
    userLogin: builder.mutation({
      query: (data) => {
        return {
          url: `user/login`,
          method: "post",
          body: data,
        };
      },
    }),
    adminLogin: builder.mutation({
      query: (data) => {
        return {
          url: `admin/login`,
          method: "post",
          body: data,
        };
      },
    }),
    forgotPasword: builder.mutation({
      query: (data) => {
        return {
          url: `admin/pass/forgot`,
          method: "post",
          body: data,
        };
      },
    }),
    resetPasword: builder.mutation({
      query: (data) => {
        return {
          url: `admin/pass/reset`,
          method: "post",
          body: data,
        };
      },
    }),

    updateProfileAdmin: builder.mutation({
      query: (data) => {
        const form_data = new FormData();
        form_data.append("first_name", data?.firstName);
        form_data.append("last_name", data?.lastName);
        form_data.append("email", data?.email);
        form_data.append("avatar", data?.avatar);
        form_data.append("location", data?.location);
        return {
          url: `admin/edit-profile`,
          method: "PATCH",
          body: form_data,
        };
      },
    }),

    logoutAdmin: builder.query({
      query: () => createRequest(`admin/logout`),
    }),
  }),
});

export const {
  useSignupUserMutation,
  useUserLoginMutation,
  useAdminLoginMutation,
  useForgotPaswordMutation,
  useLogoutAdminQuery,
  useResetPaswordMutation,
  useUpdateProfileAdminMutation
} = authApi;
