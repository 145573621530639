import { baseQueryWithReauth, createRequest, createRequestWithParams, CustomError } from "./shared";
import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/query/react";

export const courseApi = createApi({
  reducerPath: "courseApi",
  tagTypes: ["courses", "singlecourses"],
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
  endpoints: (builder) => ({
    getAllCourses: builder.query<any, { page: number; limit: number }>({
      query: ({ page, limit }) => createRequestWithParams("course/all/", { page, limit }),
      providesTags: (result, _error, _arg) =>
        result?.data ? [...result?.data?.courses?.data?.rows?.map(({ id }: { id: string }) => ({ type: "courses", id })), "courses"] : ["courses"],
    }),

    getUniversityCourses: builder.query<any, { page: number; limit: number; id: string }>({
      query: ({ page, limit, id }) => createRequestWithParams(`admin/course/in-university/${id}`, { page, limit }),
      providesTags: ["courses"],
    }),

    getACourse: builder.query<any, string>({
      query: (id) => createRequest(`admin/course/view/${id}`),
      providesTags: (result, _error, id) => (result?.data ? [{ type: "singlecourses", id }] : ["singlecourses"]),
    }),

    searchCourse: builder.query<any, { page: number; limit: number; search: string; id?: string }>({
      query: ({ page, limit, search, id }) => createRequestWithParams(`course/search/${id}`, {page, limit, search})
    }),
    deleteCourse: builder.mutation({
      query: (id) => {
        return {
          url: `admin/course/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["courses"]
    }),
    createCourse: builder.mutation<any, any>({
      query: ({ id, programId, ...data }) => {
        return {
          url: `admin/course/create/${id}/${programId?.value}`,
          method: "post",
          body: data,
        };
      },
      invalidatesTags: ["courses"]
    }),
    editCourse: builder.mutation<any, any>({
      query: ({ id, ...data }) => {
        return {
          url: `admin/course/edit/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["courses", "singlecourses"]
    }),
    createAdmission: builder.mutation<any, any>({
      query: ({ id, ...data }) => {
        return {
          url: `admin/admission/create/${id}`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useEditCourseMutation, useGetACourseQuery, useGetAllCoursesQuery, useGetUniversityCoursesQuery, useLazySearchCourseQuery, useDeleteCourseMutation, useCreateCourseMutation, useCreateAdmissionMutation } = courseApi;
