import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import useOnClickOutside from "../../hooks/useOnclickOutside";
import { useLogoutAdminQuery } from "../../redux/services";
import Modal from '../../components/shared/Modal';
import { RootState } from "../../redux/store";
import Icon from "../Icons";

const TopNav = () => {
  const [dropDown, setDropDown] = useState(false);
  const [dropDownNotification, setDropDownNotification] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const [skip, setSkip] = useState(true);
  const { isSuccess } = useLogoutAdminQuery(undefined, { skip });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const { user } = useSelector((state: RootState) => state.authStore) as any;
  const { pathname } = location

  const [openModal, setOpenModal] = useState<boolean>(false)
  const LINKS = [
    { name: "Schools", link: "schools"},
    { name: "Archived", link:"archives"},
  ]
  const LINKS2 = [
    { name: "Hotels", to: "bookings"},
    { name: "Flights", to: "flights"},
  ]
  const LINKS3 = [
    {name: "School Application", link: "application"},
    {name: "Visa Application", link: "visas"},
  ]

  const handleLogout = async () => {
    setSkip(false);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch({ type: "LOGOUT" });
    }
  }, [dispatch, isSuccess, navigate, skip]);

  useOnClickOutside(ref, () => {
    dropDown && setDropDown(false);
  });
  useOnClickOutside(notificationRef, () => {
    dropDownNotification && setDropDownNotification(false);
  });
  return (
    <>
    {openModal && <Modal heading="Sign Out" message="Are you you want to sign out" onConfirm={handleLogout} onClose={() => setOpenModal(false)} />}
    <div className="sticky top-0 w-full px-6 py-3 flex justify-between bg-white z-[1] h-[80px]">
      <div className="flex w-full justify-between">
        <div>
          {(pathname === "/schools" || pathname === "/countries" || pathname === "/archives") && (
            <div className="flex items-center gap-5">
              {LINKS.map((link, index) => (
                <NavLink key={index} to={`/${link.link}`} className={({isActive}) => isActive ? "w-fit h-[26px] flex items-center font-medium text-sm text-primary border-b-2 border-b-primary capitalize py-[27px] px-[30px]" : "w-fit h-[26px] flex items-center font-medium text-sm text-[#BFBFD4] capitalize py-[27px] px-[30px]"}>
                  {link.name}
                </NavLink>
              ))}
            </div>
          )}
          {(pathname === "/application" || pathname === "/visas" || pathname === "/direct-application") && (
            <div className="flex items-center gap-5">
              {LINKS3.map((link, index) => (
                <NavLink key={index} to={`/${link.link}`} className={({isActive}) => isActive ? "w-fit h-[26px] flex items-center font-medium text-sm text-primary border-b-2 border-b-primary capitalize py-[27px] px-[30px]" : "w-fit h-[26px] flex items-center font-medium text-sm text-[#BFBFD4] capitalize py-[27px] px-[30px]"}>
                  {link.name}
                </NavLink>
              ))}
            </div>
          )}
          {(pathname === "/bookings" || pathname === "/flights") && (
            <div className="flex items-center gap-5">
              {LINKS2.map((link, index) => (
                <NavLink key={index} to={`/${link.to}`} className={({isActive}) => isActive ? "w-fit h-[26px] flex items-center font-medium text-sm text-primary border-b-2 border-b-primary capitalize py-[27px] px-[30px]" : "w-fit h-[26px] flex items-center font-medium text-sm text-[#BFBFD4] capitalize py-[27px] px-[30px]"}>
                  {link.name}
                </NavLink>
              ))}
            </div>
          )}
        </div>
        <div className='flex items-center gap-5'>
          <div ref={notificationRef} className="relative pr-5 h-full">
            <div className="cursor-pointer flex items-center h-full" onClick={() => setDropDownNotification((val) => !val)}>
              <Icon id="notification-icon" width={24} height={24} />
            </div>
            <div
              className={`${
                dropDownNotification ? "block" : "hidden"
              } shadow-[0px_4px_20px_rgba(0,0,0,0.08)] absolute z-[1] mt-5 bg-white right-0 h-[70vh] w-[300px] rounded-[10px]`}
            ></div>
          </div>
          <div ref={ref} className="group relative ">
            <div className="pl-8">
              <li className="flex items-center pt-2 cursor-pointer" onClick={() => setDropDown((val) => !val)}>
                <img className=" w-10 h-10 mr-2 rounded-full" alt="avatar" src={user?.avatar} />
                {/* <aside>
                              <h1 className=" text-sm text-black font-semibold">{`Onboard Admin`}</h1>
                              <p className=" font-medium text-[#959595] text-[12px] leading-[14.52px] ">{capitalize('SuperAdmin') || "No Role"}</p>
                          </aside> */}
                <span className="pl-3">
                  <Icon id={"arrow-down-icon"} width={24} height={24} />
                </span>
              </li>
            </div>
            <div
              className={`${
                dropDown ? "block" : "hidden"
              } shadow-[0px_4px_20px_rgba(0,0,0,0.08)] right-0 absolute z-[1] mt-5 bg-white w-[300px] rounded-[10px]`}
            >
              <div className="px-5">
                <li
                  className="flex items-center pt-5 pb-3 cursor-pointer"
                  onClick={() => {
                    navigate("/profile")
                  }}
                >
                  <Icon id="profile-icon" width={24} height={24} />
                  <aside>
                    <p className=" font-medium text-[#1B1B1B] text-[14px] leading-[22.4px] capitalize">My Profile</p>
                  </aside>
                </li>
                <li className="text-[#B00000] flex mt-5 mb-5 cursor-pointer" onClick={() => setOpenModal(true)}>
                  <span className="ml-2">Sign out</span>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TopNav;
