import { baseQueryWithReauth, createRequest, createRequestWithParams, CustomError } from "./shared";
import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/query/react";

export const applicationApi = createApi({
  reducerPath: "applicationApi",
  tagTypes: ["application", "SingleApplication"],
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
  endpoints: (builder) => ({
    createApplication: builder.mutation<
      any,
      {
        id: string;
        body: {
          first_name: string;
          last_name: string;
          middle_name: string;
          phone_number: string;
          email: string;
          gender: string;
          nationality: string;
        };
      }
    >({
      query: ({ id, body }) => {
        return {
          url: `application/create/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    searchApplication: builder.query<any, { tracking_id: string }>({
      query: ({ tracking_id }) => createRequestWithParams(`admin/applications/search`, { tracking_id}),
    }),

    getAllApplications: builder.query<any, { page: number; limit: number, search: string }>({
      query: ({ page, limit, search }) => createRequestWithParams(`admin/applications/all`, { page, limit, search }),
      providesTags: (result, _error, _arg) => ["application"],
    }),
    getAnApplication: builder.query<any, string>({
      query: (id) => createRequest(`admin/applications/view/${id}`),
      providesTags: (result, _error, id) => (result?.data ? [{ type: "SingleApplication", id }] : ["SingleApplication"]),
    }),
    getACourse: builder.query<any, string>({
      query: (id) => createRequest(`course/one/${id}`),
      providesTags: (result, _error, id) => (result?.data ? [{ type: "application", id }] : ["application"]),
    }),
    getApplicationByStatus: builder.query<any, { page: number; limit: number; status: string }>({
      query: ({ page, limit, status }) => createRequestWithParams(`admin/applications/by-status`, { page, limit, status }),
      providesTags: (result, _error, _arg) => ["application"],
    }),
    updateApplication: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `admin/applications/update/${data?.id}`,
          method: "PATCH",
          body: {
            status: data?.status
          }
        };
      },
      invalidatesTags: ["SingleApplication", "application"],
    }),
    updateApplicationComment: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `admin/applications/update/${data?.id}`,
          method: "PATCH",
          body: {
            comment: data?.comment
          }
        };
      },
      invalidatesTags: ["SingleApplication", "application"],
    }),
    updateCourse: builder.mutation<any, any>({
      query: ({ id, ...data }) => {
        return {
          url: `admin/course/edit/${id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLazySearchApplicationQuery,
  useCreateApplicationMutation,
  useGetAllApplicationsQuery,
  useGetAnApplicationQuery,
  useGetApplicationByStatusQuery,
  useUpdateApplicationMutation,
  useUpdateApplicationCommentMutation,
  useUpdateCourseMutation,
} = applicationApi;
