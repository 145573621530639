import { useNavigate } from "react-router-dom";
import Icon from "../Icons";

const CreatePostTab = () => {
    const navigate = useNavigate();

    return (
        <div className='w-full bg-white h-[15vh]' >
            <div className='flex justify-start items-center h-full px-4' >
                <div onClick={()=> navigate(-1)} className='flex cursor-pointer mx-5' >
                    <span className='pr-2' ><Icon id='goback-icon' height={24} width={24} /></span>
                    <span>Back</span>
                </div>
            </div>
        </div>
    )
}

export default CreatePostTab;