import React from "react";
import { capitalizeAllFirstLetters, capitalizeFirstLetter } from "../../utils";

interface Section {
  header: string;
  content: string;
  images: string[];
}

interface BlogPost {
  title: string;
  subtitle: string;
  category: string;
  sections: Section[];
}

const BlogPostView: React.FC<BlogPost> = ({ title, subtitle, category, sections }) => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-[#FFF] rounded-lg z-30 my-5">
      {
        title &&
        <>
            <h1 className="text-xl font-bold mb-4 py-4">{capitalizeAllFirstLetters(title)}</h1>
            <hr />
        </>
      }
      {
        subtitle &&
        <>
            <h2 className="text-lg font-semibold mb-2 py-4">{capitalizeAllFirstLetters(subtitle)}</h2>
            <hr />
        </>
      }

      {
        category &&
        <span className="inline-block bg-blue-200 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded mb-6">
            Category: {capitalizeAllFirstLetters(category)}
        </span>
      }
      
      
      {sections && sections.map((section, index) => (
        <div key={index} className="mb-8">
          {section.header && <h3 className="text-md font-semibold mb-2">{capitalizeAllFirstLetters(section.header)}</h3>}
          {section.content && <p className="mb-4 text-sm">{capitalizeFirstLetter(section.content)}</p>}
          <div className="grid grid-cols grid-cols-1 sm:grid-cols-2">
            {section.images && section.images.map((image, idx) => (
              <img
                key={idx}
                src={image}
                alt={`Section ${index + 1} Image ${idx + 1}`}
                className="object-cover rounded-md shadow-md mx-auto w-[90%] my-5"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPostView;
