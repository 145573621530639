import React from "react";
import Icon from "../Icons";

const Footer = () => {
  return (
    <div className="bg-[#6FA7B4] p-5 md:p-10 set-bg-img h-[240px]">
      <div className="flex md:flex-row flex-col md:justify-between w-full h-full items-center pt-10">
        <div className="h-full">
          <aside>
            <img src={`${process.env.PUBLIC_URL}/static/images/Onboard Logo - White 1.svg`} alt="Logo" />
          </aside>
        </div>
        <div className="text-white h-full">
          <aside>
            <div className="flex">
              <span className="px-1">
                <Icon width={24} height={21} id="twitter-icon" />
              </span>
              <span className="px-1">
                  <a rel="noreferrer" href="https://web.facebook.com/KadirTravels" target="_blank" >
                    <Icon width={24} height={24} id="facebook-icon" />
                  </a>
              </span>
              <span className="px-1">
                <a rel="noreferrer" href="https://www.instagram.com/ktravels.ng/" target="_blank" >
                    <Icon width={24} height={24} id="instagram-icon" />
                </a>
              </span>
            </div>
            <div>
              <p className="font-normal text-[16px] pt-2">© {new Date().getFullYear()} Onboard All Rights Reserved.</p>
              <p className="font-normal text-[16px]">Terms and Conditions</p>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default Footer;
